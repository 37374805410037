import * as Sentry from "@sentry/vue";
import { QueryClient } from "@tanstack/vue-query";
import { CognitoUserSession } from "amazon-cognito-identity-js";
import { Auth } from "aws-amplify";
import { defineComponent } from "vue";
import StreamRepository from "shared/repositories/StreamRepository";
import UserRepository from "shared/repositories/UserRepository";
import { apiClient } from "shared/repositories/clients";
import ProjectRepository from "@/repositories/ProjectRepository";
import ValidationRepository from "@/repositories/ValidationRepository";

export default defineComponent({
  methods: {
    async signIn(username: string, password: string) {
      await Auth.signIn(username, password);
      this.startSessionTimeout();
      await this.setAxiosAuthToken();
      await this.loadUserData();
    },
    async signOut(queryClient: QueryClient) {
      await Auth.signOut();
      this.clearUserData();
      this.resetSessionTimeout();
      this.clearAxiosAuthToken();
      queryClient.removeQueries();
    },
    startSessionTimeout() {
      if (window.location.hostname === "localhost") {
        return;
      }
      this.resetSessionTimeout();
      this.$store.commit("setSessionStartDate", new Date());
      const timer = setInterval(() => {
        const sessionStartDate = this.$store.state.sessionStartDate;
        if (!sessionStartDate) {
          return;
        }
        const duration = new Date().getTime() - sessionStartDate.getTime();
        if (duration > 24 * 60 * 60 * 1000 - 30 * 1000) {
          if (this.$store.state.sessionTimer) {
            clearInterval(this.$store.state.sessionTimer);
          }
          this.$store.commit("setIsSessionExpired", true);
          this.$store.commit("setSessionTimer", null);
          this.$store.commit("setSessionStartDate", null);
        }
      }, 1000);
      this.$store.commit("setSessionTimer", timer);
    },
    resetSessionTimeout() {
      if (this.$store.state.sessionTimer) {
        clearInterval(this.$store.state.sessionTimer);
      }
      this.$store.commit("setIsSessionExpired", false);
      this.$store.commit("setSessionTimer", null);
      this.$store.commit("setSessionStartDate", null);
    },
    async setAxiosAuthToken() {
      const currentSession = await Auth.currentSession();
      apiClient.defaults.headers.common["Authorization"] = `Bearer ${currentSession
        .getAccessToken()
        .getJwtToken()}`;
    },
    clearAxiosAuthToken() {
      apiClient.defaults.headers.common["Authorization"] = "";
    },
    async loadAppUser() {
      const user = await UserRepository.loadPctUser();
      this.$store.commit("setUser", user);
      // this should use logger.setUser()
      // but for some reason importing logger in this file breaks the full app
      // so far unclear why
      if (process.env.NODE_ENV === "production") {
        Sentry.setUser({ email: user.email });
      }
    },
    async loadConflictingProcessesCount() {
      if (!this.hasPermission("pct_admin")) {
        this.$store.commit("setConflictingProcessesCount", null);
        return;
      }
      return ValidationRepository.loadConflictingProcessesCount().then(({ count }) => {
        this.$store.commit("setConflictingProcessesCount", count);
      });
    },
    async loadOfflineStreamCount() {
      if (!this.hasPermission("pct_admin")) {
        this.$store.commit("setOfflineStreamCount", null);
        return;
      }
      return StreamRepository.loadOfflineStreamCount().then((count) => {
        this.$store.commit("setOfflineStreamCount", count);
      });
    },
    async loadProjects() {
      const projects = await ProjectRepository.loadAllProjects();
      this.$store.commit("setProjects", projects);
    },
    async loadUserData() {
      await this.loadAppUser();
      await this.loadProjects();
      this.loadConflictingProcessesCount();
      this.loadOfflineStreamCount();
    },
    clearUserData() {
      this.$store.commit("setUser", undefined);
      this.$store.commit("setConflictingProcessesCount", null);
      this.$store.commit("setProjects", []);
      if (process.env.NODE_ENV === "production") {
        Sentry.setUser(null);
      }
    },
    async getCurrentSession() {
      try {
        return await Auth.currentSession();
      } catch (error) {
        if (error !== "No current user") {
          throw error;
        }
      }
    },
    async refreshSession(): Promise<CognitoUserSession | undefined> {
      const currentSession = await this.getCurrentSession();
      if (!currentSession) {
        return undefined;
      }
      const cognitoUser = await Auth.currentAuthenticatedUser();
      const session = await new Promise<CognitoUserSession | undefined>((resolve) => {
        cognitoUser.refreshSession(
          currentSession.getRefreshToken(),
          (error: Error, session?: CognitoUserSession) => {
            if (error) {
              return resolve(undefined);
            }
            resolve(session);
          },
        );
      });
      if (session) {
        this.startSessionTimeout();
        await this.setAxiosAuthToken();
        await this.loadUserData();
      }

      return session;
    },
  },
});

<template>
  <div
    class="flex justify-center items-center h-screen"
    v-if="!authResolved && !$store.state.isSessionExpired"
  >
    <LoadingSpinner />
  </div>
  <div
    id="wrapper"
    class="h-screen overflow-auto"
    v-if="authResolved && !$store.state.isSessionExpired"
  >
    <router-view :key="route.path"></router-view>
  </div>
  <ConfirmationModal />
  <SessionExpired v-if="$store.state.isSessionExpired" />
  <div id="tooltip"></div>
</template>

<script lang="ts">
import { defineComponent } from "vue";
import { useRoute, useRouter } from "vue-router";
import LoadingSpinner from "shared/components/loading_state/LoadingSpinner.vue";
import ConfirmationModal from "shared/components/modals/ConfirmationModal.vue";
import SessionExpired from "shared/components/other/SessionExpired.vue";
import { useSaveReturnUrl } from "shared/composables/auth";
import logger from "shared/services/logger";
import AuthMixins from "@/mixins/AuthMixins";

export default defineComponent({
  name: "App",
  components: { SessionExpired, ConfirmationModal, LoadingSpinner },
  data() {
    return {
      authResolved: false as boolean,
    };
  },
  mixins: [AuthMixins],
  async mounted() {
    let session: unknown | undefined = undefined;
    try {
      session = await this.refreshSession();
    } catch (error) {
      logger.error("Unable to refresh session", error as Error);
    } finally {
      if (!session) {
        await this.router.isReady();
        if (this.route.meta.requireLogin) {
          this.saveReturnUrl();
          this.router.replace("/log-in");
        }
      }
      setTimeout(() => {
        this.authResolved = true;
      }, 0);
    }
  },
  setup() {
    const router = useRouter();
    const route = useRoute();
    const saveReturnUrl = useSaveReturnUrl();

    return { router, route, saveReturnUrl };
  },
});
</script>

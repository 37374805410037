<template>
  <Modal :open="open" @close="$emit('closeModal')" customCls="w-11/12">
    <template #title>
      <p class="flex items-center">PCT User Management</p>
      <hr class="h-px my-4 bg-gray-200 border-0 dark:bg-gray-700" />
    </template>
    <template #content>
      <div class="flex flex-col text-left">
        <div class="overflow-auto max-h-[85vh]">
          <div class="inline-block min-w-full py-2 align-middle">
            <div class="shadow-sm">
              <table class="min-w-full divide-y divide-gray-300">
                <tbody class="divide-y divide-gray-200 bg-white">
                  <tr v-for="(user, idx) in users" :key="user._id" class="hover:bg-gray-100">
                    <td class="w-20 whitespace-nowrap px-3 py-4 text-sm text-gray-500">
                      # {{ idx + 1 }}
                    </td>
                    <td
                      v-if="user.name"
                      class="w-1/6 whitespace-nowrap py-4 pl-4 pr-3 text-sm font-medium text-gray-900 sm:pl-6 lg:pl-8"
                    >
                      {{ user.name }}
                    </td>
                    <td
                      v-else
                      class="sm:ml-6 lg:ml-8 inline-flex items-center gap-x-1.5 rounded-full bg-orange-100 px-2 py-1 text-xs font-medium text-orange-600 max-w-max"
                    >
                      <svg class="h-1.5 w-1.5 fill-orange-400" viewBox="0 0 6 6" aria-hidden="true">
                        <circle cx="3" cy="3" r="3" />
                      </svg>
                      Pending Invite
                    </td>
                    <td
                      class="w-1/6 whitespace-nowrap py-4 pl-4 pr-3 text-sm font-medium text-gray-900 sm:pl-6 lg:pl-8"
                    >
                      {{ user.email }}
                    </td>
                    <td
                      class="flex items-center whitespace-nowrap py-4 pl-4 pr-3 text-sm font-medium text-gray-900 sm:pl-6 lg:pl-8"
                    >
                      <div class="flex" v-if="hasPermission(['pct_admin'])">
                        <div
                          v-for="group in sortUserGroups(user.groups)"
                          :key="group"
                          class="pr-2 items-center"
                        >
                          <span
                            class="inline-flex items-center bg-green-100 text-green-700 rounded-full py-0.5 px-2.5 text-sm font-medium"
                          >
                            {{ userGroupMap[group] || group }}
                            <button
                              v-if="!['pct', 'app'].includes(group)"
                              type="button"
                              class="mx-0.5 inline-flex h-4 w-4 shrink-0 items-center justify-center rounded-full text-red-400 hover:bg-red-200 hover:text-red-500 focus:bg-red-500 focus:text-white focus:outline-none"
                              @click="removeUserFromGroup(group, user)"
                            >
                              <XMarkIcon class="h-4 w-4 text-red" aria-hidden="true" />
                            </button>
                          </span>
                        </div>
                      </div>
                      <div class="flex" v-if="hasPermission(['pct_admin'])">
                        <button
                          v-if="user !== currentUser || !userGroupEdit"
                          @click="
                            (currentUser = user), (userGroupEdit = true), (selectedUserGroup = '')
                          "
                        >
                          <PlusCircleIcon class="h-6 w-6 text-yellow-600" aria-hidden="true" />
                        </button>
                        <div v-if="user === currentUser && userGroupEdit" class="flex">
                          <select
                            v-model="selectedUserGroup"
                            id="groups"
                            name="groups"
                            class="block w-full rounded-md border-gray-300 py-1 pr-10 text-base focus:border-yellow-500 focus:outline-none focus:ring-yellow-500 sm:text-sm"
                          >
                            <option disabled selected value>-- select an option --</option>
                            <option
                              v-for="group in availableUserGroups"
                              :key="group"
                              :value="group"
                            >
                              {{ userGroupMap[group] }}
                            </option>
                          </select>
                          <button @click="addUserToGroup(user)" class="pl-2 pt-1">
                            <CheckCircleIcon class="h-6 w-6 text-green-400" aria-hidden="true" />
                          </button>
                          <button class="pl-1 pt-1" @click="resetEdit">
                            <XMarkIcon class="h-6 w-6 text-gray-400" aria-hidden="true" />
                          </button>
                        </div>
                      </div>
                    </td>
                    <td
                      class="w-1/6 whitespace-nowrap py-4 pl-4 pr-3 text-sm font-medium text-gray-900 sm:pl-6 lg:pl-8"
                      v-if="hasPermission(['pct_admin'])"
                    >
                      <div class="flex items-center">
                        <TrackingEntityTag
                          :entity="
                            trackingEntities?.find((entity) => entity._id === user.tracking_entity)
                          "
                        ></TrackingEntityTag>
                        <button
                          class="pl-4"
                          v-if="user !== currentUser || !userGroupEdit"
                          @click="editTrackingEntity(user)"
                        >
                          <PencilIcon class="h-5 w-5 text-gray-600" aria-hidden="true" />
                        </button>
                        <div v-if="user === currentUser && trackingEntityEdit" class="flex">
                          <select
                            v-model="selectedTrackingEntity"
                            id="trackingEntities"
                            name="trackingEntities"
                            class="block w-full rounded-md border-gray-300 py-1 pr-10 text-base focus:border-yellow-500 focus:outline-none focus:ring-yellow-500 sm:text-sm"
                          >
                            <option disabled selected value>-- select an option --</option>
                            <option :value="null">NO ENTITY</option>
                            <option
                              v-for="entity in trackingEntities"
                              :key="entity._id"
                              :value="entity._id"
                            >
                              {{ entity.name }}
                            </option>
                          </select>
                          <button @click="updateTrackingEntity(user)" class="pl-2 pt-1">
                            <CheckCircleIcon class="h-6 w-6 text-green-400" aria-hidden="true" />
                          </button>
                          <button class="pl-1 pt-1" @click="resetEdit">
                            <XMarkIcon class="h-6 w-6 text-gray-400" aria-hidden="true" />
                          </button>
                        </div>
                      </div>
                    </td>
                    <td
                      class="w-1/6 whitespace-nowrap py-4 pl-4 pr-3 text-sm font-medium text-gray-900 sm:pl-6 lg:pl-8"
                    >
                      <div
                        class="flex items-center justify-end"
                        v-if="hasPermission(['pct_admin'])"
                      >
                        <button
                          v-if="user !== currentUser || !removeUserEdit"
                          @click="
                            currentUser = user;
                            removeUserEdit = true;
                          "
                        >
                          <TrashIcon class="h-5 w-5 text-gray-400" aria-hidden="true" />
                        </button>
                        <div v-if="removeUserEdit && user === currentUser" class="flex">
                          <button @click="resetEdit">
                            <XMarkIcon class="h-6 w-6 text-gray-400" aria-hidden="true" />
                          </button>
                          <button @click="removeUserFromPct(user)">
                            <CheckCircleIcon class="h-6 w-6 text-orange-400" aria-hidden="true" />
                          </button>
                        </div>
                      </div>
                    </td>
                  </tr>
                </tbody>
              </table>
            </div>
            <div class="pt-4">
              <button v-if="!addUserEdit">
                <PlusIcon
                  class="h-10 w-10 p-2 shadow-sm border-2 border-yellow-600 rounded-full text-yellow-700 hover:bg-yellow-600 hover:text-white focus:outline-none"
                  @click="addUserEdit = true"
                ></PlusIcon>
              </button>
              <div class="flex" v-if="addUserEdit">
                <input
                  v-model="userToAddEmail"
                  type="text"
                  name="user-to-add-email"
                  id="user-to-add-email"
                  class="ml-1 block w-60 rounded-md border-gray-300 shadow-sm focus:border-yellow-500 focus:ring-yellow-500 sm:text-sm"
                  placeholder="Email..."
                />
                <div class="flex">
                  <button @click="inviteUserToPct()" class="pl-2 pt-1">
                    <EnvelopeIcon class="h-6 w-6 text-yellow-600" aria-hidden="true" />
                  </button>
                  <button
                    @click="resetEdit(), (userInviteValidationError = false)"
                    class="pl-1 pt-1"
                  >
                    <XMarkIcon class="h-6 w-6 text-gray-400" aria-hidden="true" />
                  </button>
                </div>
              </div>
              <small v-if="userInviteValidationError" class="ml-1 text-red-500"
                >Invalid input, provide email in right format.</small
              >
            </div>
          </div>
        </div>
      </div>
    </template>
  </Modal>

  <InviteNotification
    :show="showInviteNotification"
    :message="inviteNotificationMessage"
    @closeNotification="(userInviteValidationError = false), (showInviteNotification = false)"
  ></InviteNotification>
</template>

<script lang="ts">
import {
  PlusIcon,
  EnvelopeIcon,
  TrashIcon,
  PlusCircleIcon,
  CheckCircleIcon,
  XMarkIcon,
  PencilIcon,
} from "@heroicons/vue/20/solid";
import { defineComponent, PropType } from "vue";
import Modal from "shared/components/modals/Modal.vue";
import { useTrackingEntities } from "shared/composables/validation";
import UserRepository from "shared/repositories/UserRepository";
import logger from "shared/services/logger";
import { User, UserGroup } from "shared/types/User";
import TrackingEntityTag from "@/components/other/TrackingEntityTag.vue";
import InviteNotification from "@/views/user/components/InviteNotification.vue";

export default defineComponent({
  name: "UserManagement",
  props: {
    open: {
      type: Boolean,
      requried: true,
    },
    users: {
      type: Object as PropType<User[]>,
      required: true,
    },
  },
  emits: ["closeModal", "addUser", "removeUser", "updateUser"],
  data() {
    return {
      selectedUserGroup: "" as string,
      selectedTrackingEntity: null as string | null,
      currentUser: null as User | null,
      showInviteNotification: false as boolean,
      inviteNotificationMessage: "" as string,
      userGroupEdit: false as boolean,
      trackingEntityEdit: false as boolean,
      removeUserEdit: false as boolean,
      addUserEdit: false as boolean,
      userToAddEmail: "" as string,
      showUserNameField: false as boolean,
      userInviteValidationError: false as boolean,
    };
  },
  components: {
    TrackingEntityTag,
    InviteNotification,
    Modal,
    XMarkIcon,
    PlusCircleIcon,
    PlusIcon,
    EnvelopeIcon,
    TrashIcon,
    CheckCircleIcon,
    PencilIcon,
  },
  computed: {
    availableUserGroups() {
      return [
        "pct",
        "pct_admin",
        "pct_invite_base",
        "pct_tracking_admin_prd_review",
        "pct_tracking_person_gad",
      ].filter(
        (item) =>
          !this.currentUser ||
          (!this.currentUser.groups.includes(item as UserGroup) &&
            (!this.currentUser.tracking_entity ||
              (this.currentUser.tracking_entity && item !== "pct_admin"))),
      ) as UserGroup[];
    },
    userGroupMap() {
      return {
        pct: "PCT",
        app: "App",
        app_admin: "APP Admin",
        pct_admin: "PCT Admin",
        pct_invite_base: "Invite",
        pct_tracking_admin_prd_review: "PRD Admin Review",
        pct_tracking_person_gad: "Person GAD Tracker",
      } as Partial<Record<UserGroup, string>>;
    },
  },
  methods: {
    updateTrackingEntity(user: User) {
      return UserRepository.updateUserTrackingEntity(user.username, this.selectedTrackingEntity)
        .then((data) => {
          this.$emit("updateUser", data);
        })
        .catch((error) => {
          if (error?.response?.status !== 404) {
            logger.error(error);
            alert("Unable to update user tracking entity");
          }
        })
        .finally(() => {
          this.resetEdit();
        });
    },
    addUserToGroup(user: User, addUserAfterUpdate = false) {
      return UserRepository.addUserToGroups(user.username, [this.selectedUserGroup])
        .then((data) => {
          if (addUserAfterUpdate) {
            this.$emit("addUser", data);
          } else {
            this.$emit("updateUser", data);
          }
        })
        .catch((error) => {
          if (error?.response?.status !== 404) {
            logger.error(error);
            alert("Unable to update user group");
          }
        })
        .finally(() => {
          this.resetEdit();
        });
    },
    removeUserFromGroup(group: UserGroup, user: User) {
      return UserRepository.removeUserFromGroups(user.username, [group])
        .then((data) => {
          if (!data.groups.includes("pct")) {
            this.$emit("removeUser", data);
          } else {
            this.$emit("updateUser", data);
          }
        })
        .catch((error) => {
          if (error?.response?.status !== 404) {
            logger.error(error);
            alert("Unable to update user group");
          }
        })
        .finally(() => {
          this.resetEdit();
        });
    },

    removeUserFromPct(user: User) {
      this.removeUserFromGroup("pct", user);
      this.resetEdit();
    },
    inviteUserToPct() {
      if (this.userToAddEmail === "" || !/^[^\s@]+@[^\s@]+\.[^\s@]+$/.test(this.userToAddEmail)) {
        this.userInviteValidationError = true;
        return;
      }
      this.userInviteValidationError = false;
      return UserRepository.invitePctUser(this.userToAddEmail.trim())
        .then((data) => {
          this.$emit("addUser", data);
          this.inviteNotificationMessage = "Successfully invited tracker!";
          this.showInviteNotification = true;
          this.resetEdit();
        })
        .catch((error) => {
          if (error?.response?.status !== 409) {
            logger.error(error);
            alert("Unable to invite user");
          } else {
            this.inviteNotificationMessage = "Error, user has already an account!";
            this.showInviteNotification = true;
          }
        });
    },
    resetEdit() {
      this.userGroupEdit = false;
      this.removeUserEdit = false;
      this.trackingEntityEdit = false;
      this.addUserEdit = false;
      this.currentUser = null;
      this.userToAddEmail = "";
    },
    sortUserGroups(groups: UserGroup[]) {
      const priorityString = "pct";
      return groups.sort((a, b) =>
        a === priorityString ? -1 : b === priorityString ? 1 : a.localeCompare(b),
      );
    },
    editTrackingEntity(user: User) {
      this.currentUser = user;
      this.trackingEntityEdit = true;
      this.selectedTrackingEntity = user.tracking_entity;
    },
  },
  setup() {
    const { trackingEntities } = useTrackingEntities();
    return {
      trackingEntities,
    };
  },
});
</script>

<template>
  <!-- Edit Modal -->
  <TransitionRoot as="template" :show="this.$parent.removeModalOpen">
    <Dialog as="div" class="relative z-10 bg-red-100" @close="this.$parent.removeModalOpen = false">
      <TransitionChild
        as="template"
        enter="ease-out duration-300"
        enter-from="opacity-0"
        enter-to="opacity-100"
        leave="ease-in duration-200"
        leave-from="opacity-100"
        leave-to="opacity-0"
      >
        <div class="fixed inset-0 bg-gray-500/75 transition-opacity" />
      </TransitionChild>

      <div class="fixed inset-0 z-10 overflow-y-auto">
        <div
          class="flex min-h-full items-end justify-center p-4 text-center sm:items-center sm:p-0"
        >
          <TransitionChild
            as="template"
            enter="ease-out duration-300"
            enter-from="opacity-0 translate-y-4 sm:translate-y-0 sm:scale-95"
            enter-to="opacity-100 translate-y-0 sm:scale-100"
            leave="ease-in duration-200"
            leave-from="opacity-100 translate-y-0 sm:scale-100"
            leave-to="opacity-0 translate-y-4 sm:translate-y-0 sm:scale-95"
          >
            <DialogPanel
              class="relative transform overflow-hidden bg-white rounded-lg px-2 pb-4 pt-5 text-left shadow-xl transition-all sm:my-8 sm:w-full sm:max-w-sm sm:p-2"
            >
              <div class="rounded-md p-3">
                <div class="ml-3">
                  <div v-if="this.mode == 'project'">
                    <div class="sm:flex sm:items-start">
                      <div
                        class="mx-auto flex h-12 w-12 shrink-0 items-center justify-center rounded-full bg-red-100 sm:mx-0 sm:h-10 sm:w-10"
                      >
                        <ExclamationTriangleIcon class="h-6 w-6 text-red-600" aria-hidden="true" />
                      </div>
                      <h3 class="pb-4 pl-4 text-sm font-medium text-red-800">
                        Willst du das Projekt
                        <b>{{ this.$parent.currentObject.name }}</b> wirklich löschen?
                        <br />
                        <br />
                        Mit dem Löschen des Projekts werden alle verknüpften Streams, Features und
                        Projekt Permissions aller dem Projekt zugehörigen Nutzer ebenfalls gelöscht
                        und können nicht wiederhergestellt werden!
                      </h3>
                    </div>
                  </div>
                  <div v-else>
                    <h3 class="text-sm font-medium text-red-800">
                      Willst du <b>{{ this.$parent.currentObject.name }}</b> wirklich löschen?
                      <br />
                      <br />
                      Gelöschte Elemente können nicht wiedergestelt werden.
                    </h3>
                  </div>
                </div>
                <button
                  type="submit"
                  class="float-right mr-3 inline-flex justify-center rounded-md border border-transparent bg-red-500 px-4 py-2 text-sm font-medium text-white shadow-sm hover:bg-red-700"
                  @click="deleteItem()"
                >
                  Löschen
                </button>
              </div>
            </DialogPanel>
          </TransitionChild>
        </div>
      </div>
    </Dialog>
  </TransitionRoot>
</template>

<script>
import { Dialog, DialogPanel, TransitionChild, TransitionRoot } from "@headlessui/vue";
import { ExclamationTriangleIcon } from "@heroicons/vue/24/solid";
import StreamRepository from "shared/repositories/StreamRepository";
import logger from "shared/services/logger";
import ProjectRepository from "@/repositories/ProjectRepository";

export default {
  name: "RemoveModal",
  props: ["mode"],
  data() {
    return {};
  },
  components: {
    ExclamationTriangleIcon,
    DialogPanel,
    Dialog,
    TransitionChild,
    TransitionRoot,
  },
  methods: {
    deleteItem() {
      this.$parent.removeModalOpen = false;

      if (this.$parent.model === "project") {
        ProjectRepository.deleteProject(
          this.$parent.project.customer_name,
          this.$parent.project.site_id,
        )
          .then(() => {
            this.$emit("reloadmetadata", "first");
          })
          .catch((error) => {
            logger.error(error);
          });
      } else if (this.$parent.model === "analytics") {
        ProjectRepository.updateProject(
          this.$parent.project.customer_name,
          this.$parent.project.site_id,
          {
            features: this.$parent.features
              .filter((feature) => feature.name !== this.$parent.currentObject.name)
              .map((feature) => feature.name),
          },
        )
          .then(() => {
            // Remove locally
            for (let i = 0; i < this.$parent.project[this.mode].length; i++) {
              if (this.$parent.project[this.mode][i]["id"] === this.$parent.currentObject["id"]) {
                this.$parent.project[this.mode].splice(i, 1);
              }
            }
          })
          .catch((error) => {
            logger.error(error);
          });
      } else {
        const streamIndex = this.$parent.project[this.mode].findIndex(
          (s) => s._id === this.$parent.currentObject.id,
        );
        const stream = this.$parent.project[this.mode][streamIndex];
        StreamRepository.deleteStream(stream.customer_name, stream.site_id, stream.camera_id)
          .then(() => {
            this.$parent.project[this.mode].splice(streamIndex, 1);
          })
          .catch((error) => {
            logger.error(error);
          });
      }
    },
  },
};
</script>

<template>
  <LoginComponent :userPasswordLogin="handleUserPasswordLogin" :hideFooter="true" />
</template>

<script lang="ts">
import { defineComponent } from "vue";
import { useRouter } from "vue-router";
import { useGetReturnUrl } from "shared/composables/auth";
import LoginComponent from "shared/views/auth/Login.vue";
import AuthMixins from "@/mixins/AuthMixins";

export default defineComponent({
  name: "Login",
  mixins: [AuthMixins],
  components: {
    LoginComponent,
  },
  methods: {
    async handleUserPasswordLogin(user: string, password: string): Promise<void> {
      await this.signIn(user, password);
      this.router.push(this.getReturnUrl());
    },
  },
  setup() {
    const router = useRouter();
    const getReturnUrl = useGetReturnUrl();

    return { router, getReturnUrl };
  },
});
</script>

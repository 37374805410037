<template>
  <div class="flex flex-col pb-6">
    <div class="-my-2 overflow-x-auto sm:-mx-6 lg:-mx-8">
      <div class="inline-block min-w-full px-8 py-2 align-middle">
        <div class="overflow-hidden">
          <table class="min-w-full">
            <tbody class="">
              <template v-for="(stream, streamIndex) in streams" :key="stream.name">
                <tr class="hover:bg-gray-100">
                  <th
                    colspan="2"
                    scope="colgroup"
                    class="py-4 text-left text-sm font-extrabold text-gray-700"
                  >
                    <div class="flex gap-6 items-center" :id="`stream_${getCameraId(stream)}`">
                      {{ stream.name }}
                      <span
                        v-if="!isCameraAttached(stream)"
                        class="bg-red-400 text-white font-normal rounded px-2 py-1 text-sm"
                      >
                        camera detached
                      </span>
                    </div>
                  </th>
                  <td class="py-4">
                    <div class="flex justify-end gap-6">
                      <button
                        v-if="isCameraAttached(stream)"
                        type="button"
                        class="text-gray-600 text-sm flex gap-2 items-center"
                        @click="detachCamera(stream)"
                      >
                        <BookmarkSlashIcon class="w-5 h-5" />
                        Detach Camera
                      </button>
                      <button
                        type="button"
                        class=""
                        @click="
                          removeModalOpen = true;
                          currentObject = stream;
                        "
                      >
                        <TrashIcon class="h-5 w-5" aria-hidden="true" />
                      </button>
                    </div>
                  </td>
                </tr>
                <tr
                  v-for="(property, propIndex) in stream.properties.filter(
                    (property) =>
                      isCameraAttached(stream) ||
                      !['resolution_width', 'resolution_height', 'fps', 'camera_preset'].includes(
                        property.field_identifier,
                      ),
                  )"
                  :key="property.field_identifier"
                  :class="[
                    propIndex === 0 ? 'border-gray-300' : 'border-gray-200',
                    'border-t hover:bg-gray-100',
                  ]"
                >
                  <td class="py-4 text-sm font-medium text-gray-500">
                    {{ property.field_name_text }}
                  </td>
                  <td class="py-4 text-sm text-gray-500">{{ formatValue(property) }}</td>
                  <td class="py-4 text-right text-sm font-medium">
                    <div v-if="!property.readonly">
                      <button
                        type="button"
                        class="font-medium text-yellow-700 hover:text-yellow-600"
                        @click="handleUpdateClick(property, propIndex, stream, streamIndex)"
                      >
                        Update
                      </button>
                    </div>
                  </td>
                </tr>
              </template>
            </tbody>
          </table>
        </div>
      </div>
    </div>
    <div class="py-4">
      <button
        class="focus:outline-none inline-flex justify-center rounded-md border border-transparent bg-yellow-600 px-4 py-2 text-sm font-medium text-white shadow-sm hover:bg-yellow-700 focus:ring-2 focus:ring-yellow-500 focus:ring-offset-2"
        @click="addStreamModalOpen = true"
      >
        <PlusIcon class="-ml-1 mr-3 h-5 w-5" aria-hidden="true" />
        Stream hinzufügen
      </button>
    </div>
  </div>
  <!-- Modals -->
  <EditModal ref="editModal"></EditModal>
  <AddStreamModal ref="addStreamModal"></AddStreamModal>
  <RemoveModal v-bind:mode="'streams'" ref="removeStreamModal"></RemoveModal>
  <AwsStreamIdModal
    v-if="isAwsStreamIdModalOpen"
    @close="isAwsStreamIdModalOpen = false"
    @update="handleAwsStreamIdUpdate"
    :stream="project.streams[streamIdx]"
  />
</template>

<script>
import { BookmarkSlashIcon } from "@heroicons/vue/24/outline";
import { TrashIcon, PlusIcon } from "@heroicons/vue/24/solid";
import StreamRepository from "shared/repositories/StreamRepository";
import logger from "shared/services/logger";
import AddStreamModal from "@/views/project_console/components/AddStreamModal.vue";
import AwsStreamIdModal from "@/views/project_console/components/AwsStreamIdModal.vue";
import EditModal from "@/views/project_console/components/EditModal.vue";
import RemoveModal from "@/views/project_console/components/RemoveModal.vue";
import { cameraPresets } from "@/views/project_console/projectConsole";

export default {
  name: "EditStreamsComponent",
  props: ["project"],
  watch: {
    project: {
      handler(changedObject) {
        this.customer_name = changedObject.customer_name;
        this.site_id = changedObject.site_id;

        const streams = [];

        for (let i = 0; i < this.project.streams.length; i++) {
          const entry = {
            name: "Stream " + (i + 1),
            id: this.project.streams[i]._id,
            properties: [
              {
                field_name_text: "Kameraname",
                field_name_value: this.project.streams[i].name,
                modal_field_type: "text",
                field_identifier: "name",
              },
              {
                field_name_text: "Kamera-ID",
                field_name_value: this.project.streams[i].camera_id,
                modal_field_type: "text",
                field_identifier: "camera_id",
                readonly: true,
              },
              {
                field_name_text: "AWS Stream Name",
                field_name_value: this.project.streams[i].aws_stream_id,
                modal_field_type: "text",
                field_identifier: "aws_stream_id",
              },
              {
                field_name_text: "Camera Preset",
                field_name_value: this.getCameraPresetValue(this.project.streams[i]),
                modal_field_type: "select",
                field_identifier: "camera_preset",
                options: [
                  {
                    value: "vivotek_5mp",
                    text: "Vivotek 5MP",
                  },
                  {
                    value: "custom",
                    text: "Custom",
                    hidden: true,
                  },
                ],
              },
              {
                field_name_text: "Auflösung-Breite",
                field_name_value: this.project.streams[i].resolution_width,
                modal_field_type: "number",
                field_identifier: "resolution_width",
              },
              {
                field_name_text: "Auflösung-Höhe",
                field_name_value: this.project.streams[i].resolution_height,
                modal_field_type: "number",
                field_identifier: "resolution_height",
              },
              {
                field_name_text: "FPS",
                field_name_value: this.project.streams[i].fps,
                modal_field_type: "number",
                field_identifier: "fps",
              },
              {
                field_name_text: "Blur",
                field_name_value: this.project.streams[i].rtp,
                modal_field_type: "boolean",
                field_identifier: "rtp",
              },
              {
                field_name_text: "Bilddaten Startzeit",
                field_name_value: this.project.streams[i].image_start,
                modal_field_type: "time",
                field_identifier: "image_start",
              },
              {
                field_name_text: "Bilddaten Endzeit",
                field_name_value: this.project.streams[i].image_end,
                modal_field_type: "time",
                field_identifier: "image_end",
              },
              {
                field_name_text: "Bilddaten Wochentage",
                field_name_value: this.project.streams[i].image_weekdays,
                modal_field_type: "select",
                field_identifier: "image_weekdays",
                options: [
                  {
                    value: ["Mon", "Tue", "Wed", "Thu", "Fri"],
                    text: "MON-FRI",
                  },
                  {
                    value: ["Mon", "Tue", "Wed", "Thu", "Fri", "Sat"],
                    text: "MON-SAT",
                  },
                  {
                    value: ["Mon", "Tue", "Wed", "Thu", "Fri", "Sat", "Sun"],
                    text: "MON-SUN",
                  },
                ],
              },
              {
                field_name_text: "Archivierungsdauer in Tagen",
                field_name_value: this.project.streams[i].archive_duration,
                modal_field_type: "number",
                field_identifier: "archive_duration",
              },
            ],
          };
          streams.push(entry);
        }
        this.streams = streams;
      },
      deep: true,
    },
  },
  data() {
    return {
      editModalOpen: false,
      addStreamModalOpen: false,
      removeModalOpen: false,
      model: "stream",
      modalFieldText: "",
      modalFieldValue: "",
      modalFieldId: "",
      modalFieldType: "",
      currentObject: {},
      fieldIdx: 0,
      streamIdx: 0,
      cameraIdentifier: 0, // RDS ID
      customer_name: "",
      site_id: "",
      streams: [],
      isAwsStreamIdModalOpen: false,
    };
  },
  components: {
    AwsStreamIdModal,
    EditModal,
    AddStreamModal,
    RemoveModal,
    TrashIcon,
    PlusIcon,
    BookmarkSlashIcon,
  },
  mounted() {
    this.scrollToStream();
  },
  methods: {
    handleUpdateClick(property, propIndex, stream, streamIndex) {
      this.fieldIdx = propIndex;
      this.streamIdx = streamIndex;
      this.modalFieldType = property.modal_field_type;
      this.modalFieldText = property.field_name_text;
      this.modalFieldValue = property.field_name_value;
      this.modalFieldId = property.field_identifier;
      this.modalFieldOptions = property.options;
      this.cameraIdentifier = stream.id;
      this.isAwsStreamIdModalOpen = property.field_identifier === "aws_stream_id";
      this.editModalOpen = !this.isAwsStreamIdModalOpen;
    },
    getCameraPresetValue(stream) {
      const matchingPreset = Object.entries(cameraPresets)
        .map(([key, preset]) => ({ key, preset }))
        .find(({ preset }) =>
          Object.entries(preset).every(([key, value]) => stream[key] === value),
        );
      return matchingPreset?.key || "custom";
    },
    formatValue(property) {
      if (Array.isArray(property.field_name_value)) {
        return property.field_name_value.join(", ");
      }
      if (property.modal_field_type === "select") {
        return (
          property.options.find((option) => option.value === property.field_name_value)?.text ||
          property.field_name_value
        );
      }
      return property.field_name_value;
    },
    detachCamera(stream) {
      if (window.confirm("Are you sure to detach camera?")) {
        const cameraId = stream.properties.find(
          (property) => property.field_identifier === "camera_id",
        )?.field_name_value;
        StreamRepository.detachCamera(this.project.customer_name, this.project.site_id, cameraId)
          .then((updatedStream) => {
            const streams = this.project.streams;
            const index = streams.findIndex((s) => s._id === stream.id);
            streams.splice(index, 1, updatedStream);
          })
          .catch((error) => {
            logger.error(error);
            alert("Unable to detach camera");
          });
      }
    },
    isCameraAttached(stream) {
      return !!stream.properties.find((property) => property.field_identifier === "aws_stream_id")
        ?.field_name_value;
    },
    getCameraId(stream) {
      return stream.properties.find((property) => property.field_identifier === "camera_id")
        ?.field_name_value;
    },
    scrollToStream() {
      const cameraId = this.$route.query.camera_id;
      if (cameraId) {
        setTimeout(() => {
          const element = document.getElementById(`stream_${cameraId}`);
          element?.scrollIntoView({ block: "start" });
        }, 100);
      }
    },
    handleAwsStreamIdUpdate(stream) {
      Object.entries(stream).forEach(([key, value]) => {
        const streams = this.project.streams;
        streams[this.streamIdx][key] = value;
      });
      this.isAwsStreamIdModalOpen = false;
    },
  },
};
</script>
